window.instituicaoIntro = function (){
  $("#anchor-item-5").on('click', function(evt){
    let target = $(evt.target).parent();
    $('.listCategoryInstitution').find('li').removeClass('active');

    $('#content-item-5').css('display', 'flex');
    target.addClass('active');
    $('#content-item-7, #content-item-8, #content-item-19,#content-item-20').css('display', 'none');
  });


  $("#anchor-item-19").on('click', function(evt){
    let target = $(evt.target).parent();
    $('.listCategoryInstitution').find('li').removeClass('active');

    $('#content-item-19').css('display', 'flex');
    target.addClass('active');
    $('#content-item-5, #content-item-7, #content-item-8, #content-item-20').css('display', 'none');
  });


  $("#anchor-item-7").on('click', function(evt){
    let target = $(evt.target).parent();
    $('.listCategoryInstitution').find('li').removeClass('active');

    $('#content-item-7').css('display', 'flex');
    target.addClass('active');
    $('#content-item-5, #content-item-8, #content-item-19, #content-item-20').css('display', 'none');
  });


  $("#anchor-item-8").on('click', function(evt){
    let target = $(evt.target).parent();
    $('.listCategoryInstitution').find('li').removeClass('active');

    $('#content-item-8').css('display', 'flex');
    target.addClass('active');
    $('#content-item-5, #content-item-7, #content-item-19, #content-item-20').css('display', 'none');
  });


  $("#anchor-item-20").on('click', function(evt){
    let target = $(evt.target).parent();
    $('.listCategoryInstitution').find('li').removeClass('active');

    $('#content-item-20').css('display', 'flex');
    target.addClass('active');
    $('#content-item-5, #content-item-7, #content-item-8, #content-item-19').css('display', 'none');
  });
}
